import styled from "styled-components"
import SectionWrapper from "../section-wrapper/section-wrapper.component"
import Grid from "@mui/material/Grid"

export const RelatedAuthorsTitle = styled.h2`
  color: #0f1418;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.88px;
  line-height: 39px;
  padding-bottom: 2em;
`
export const CustomGrid = styled(Grid)`
  div {
    justify-content: flex-start;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 1em;
  }
`
export const CustomSectionWrapper = styled(SectionWrapper)`
  &.dark {
    h1,
    p {
      color: ${({ theme }) => theme.palette.text.secondary};
    }
    h2 {
      color: ${({ theme }) => theme.palette.text.secondary};
      text-align: center;
    }
  }
`
