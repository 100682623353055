import React from "react"
import Layout from "../../components/layout"
import HeroBanner from "../../layouts/HeroBanner"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Grid } from "@mui/material"
import * as S from "../../styles/templates/book.styles"
import FluidImage from "../../components/fluid-image/fluid-image"
import { graphql } from "gatsby"
import BuySection from "../../components/buy-section/buy-section.component"
import RelatedAuthors from "../../components/related-authors/related-authors.component"

export const query = graphql`
  query ServiceQuery($id: String!) {
    amazon: file(relativePath: { eq: "amazon.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }
    barnes: file(relativePath: { eq: "barnes.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }
    bg: file(relativePath: { eq: "dark-bg.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }
    author2: file(relativePath: { eq: "author2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }
    allWpSuccessStory {
      edges {
        node {
          uri
          id
          successStoryFields {
            author {
              ... on WpAuthor {
                id
                featuredImage {
                  node {
                    altText
                    sourceUrl
                  }
                }
                title
                slug
                uri
                authorFields {
                  heroTagName
                }
              }
            }
          }
        }
      }
    }
    wpSuccessStory(id: { eq: $id }) {
      successStoryFields {
        author {
          ... on WpAuthor {
            id
            featuredImage {
              node {
                altText
                sourceUrl
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                  }
                }
              }
            }
            title
            authorFields {
              heroTagName
            }
          }
        }
        book {
          ... on WpBook {
            id
            booksFields {
              amazonLink
              barnesNobleLink
            }
            featuredImage {
              node {
                altText
                sourceUrl
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                  }
                }
              }
            }
            uri
          }
        }
        tagTitleText
        subtitle
      }
      slug
      title
      content
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      id
      uri
    }
  }
`

const SuccessStory = ({ data }) => {
  const {
    successStoryFields: { author, book, tagTitleText, subtitle },
    title,
    content,
    seo,
    id,
    slug,
  } = data.wpSuccessStory
  const canonical = `${process.env.GATSBY_WEBSITE_URL}/success-story/${slug}/`
  const parse = require("html-react-parser")

  const authors = data.allWpSuccessStory.edges

  const filterAuthors =
    authors &&
    authors.length > 0 &&
    authors.filter(item => item.node.id !== id).slice(0, 3)

  return (
    <Layout seo={{ ...seo, canonical }}>
      <HeroBanner
        image={data.bg}
        tagText={tagTitleText}
        title={title}
        description={subtitle}
        className="blur"
      />
      <SectionWrapper contained bg="gray">
        <Grid container spacing={9} justify="center">
          <S.BookContent item xs={12} sm={7}>
            {content && parse(content)}
          </S.BookContent>
          <S.CustomGridItem item xs={12} sm={4}>
            <S.SucessStoryContainer>
              <FluidImage
                img={author.featuredImage && author.featuredImage.node}
              />
              <BuySection
                bookLink={book.uri}
                title="Find out more information about the book"
                img={book.featuredImage && book.featuredImage.node}
                buyButtons={[
                  {
                    img: data.amazon,
                    target: "_blank",
                    href: book.booksFields.amazonLink,
                  },
                  {
                    img: data.barnes,
                    target: "_blank",
                    href: book.booksFields.barnesNobleLink,
                  },
                ]}
              />
            </S.SucessStoryContainer>
          </S.CustomGridItem>
        </Grid>
      </SectionWrapper>
      <RelatedAuthors
        className="dark"
        authors={filterAuthors}
        bg="main"
        numberPerRow="sd"
      />
    </Layout>
  )
}

export default SuccessStory
