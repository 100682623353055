import React from "react"
import * as S from "./related-authors.styles.jsx"
import { Grid } from "@mui/material" 
import AuthorCard from "../author-card/author-card.component"

const RelatedAuthors = ({ authors, numberPerRow, bg, className }) => {
  if (!authors) return null

  return (
    <S.CustomSectionWrapper
      contained
      bg={bg}
      maxWidth="md"
      className={className}
    >
      <S.RelatedAuthorsTitle>More Success Stories</S.RelatedAuthorsTitle>
      <Grid container>
        {authors.map(author => (
          <S.CustomGrid
            item
            xs={6}
            sm={12 / numberPerRow ? 12 / numberPerRow : 4}
          >
            <AuthorCard
              slug={author.node.uri}
              authorImage={
                author.node.successStoryFields.author.featuredImage &&
                author.node.successStoryFields.author.featuredImage.node
              }
              authorName={author.node.successStoryFields.author.title}
              role={
                author.node.successStoryFields.author.authorFields.heroTagName
              }
            />
          </S.CustomGrid>
        ))}
      </Grid>
    </S.CustomSectionWrapper>
  )
}

export default RelatedAuthors
